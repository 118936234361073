.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-left: 0;
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

///----------

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  background-color: #0c9;
  color: #fff;
  border-radius: 35px;
  border-width: 1px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  font-size: 18px;
}

.my-float {
  margin-top: 22px;
}

.float2{
	position:fixed;
	width:40px;
	height:40px;
	top:10px;
	right:60px;
	background-color:#0C9;
	color:#FFF;
  border-radius:35px;
  border-width:1px;
	text-align:center;
  box-shadow: 2px 2px 3px #999;
  font-size:18px;
}

.datepick{
  display: inline-block;
	width: auto;
	height:40px;
  text-align:center;
  line-height: 40px;
}

.smoothOption {
	position:fixed;
	width:40px;
	height:40px;
	top:10px;
	left:590px;
	background-color:#90C;
	color:#FFF;
  border-radius:35px;
  border-width:1px;
	text-align:center;
  box-shadow: 2px 2px 3px #999;
  font-size:16px;
}

.tip {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  visibility: hidden;
  border: 1px solid black;
  background-color: lightgray;
  width: 50px;
  height: 16px;
  overflow: hidden;
  text-align: center;
  font-size: 9pt;
  font-family: Tahoma, Optima, Arial, Helvetica;
  color: black;
}

.brush .selection {
  stroke: #fff;
  fill: red;
  fill-opacity: 0.125;
  shape-rendering: crispEdges;
}

div.date-indicator-last {
  display: inline-block;
  width: 200px;
  height: 36px;
  background-color: rgb(0, 180, 204);
  color: #fff;
  border-radius: 15px;
  border-width: 1px;
  text-align: center;
  line-height: 36px;
  box-shadow: 2px 2px 3px #999;
  font-size: 18px;
}

div.date-indicator-start {
  display: inline-block;
  width: 200px;
  height: 36px;
  background-color: rgb(204, 184, 0);
  color: #fff;
  border-radius: 15px;
  border-width: 1px;
  text-align: center;
  line-height: 36px;
  box-shadow: 2px 2px 3px #999;
  font-size: 18px;
}

.total-counter {
  margin-left: 1rem;
  padding: 0 1rem;
  box-shadow: 2px 2px 3px #999;
  width: 80px;
  display: inline-block;
}

.total-counter.f {
  background-color: #bcffcb;
}

.total-counter.b {
  background-color: #afafff;
}

div.date-indicator-start span.modified, div.date-indicator-last span.modified {
  color: yellow;
}

div.keys {
  display: inline-block;
  box-shadow: 2px 2px 3px #999;
  width: 320px;
}

.btn-add-key {
  width: 28px;
  height: 28px;
  background-color: #0c9;
  color: #fff;
  border-radius: 14px;
  border-width: 1px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  font-size: 14px;
  margin-left: 0.5rem;
}

.btn-del-key {
  width: 28px;
  height: 28px;
  background-color: rgb(204, 0, 187);
  color: #fff;
  border-radius: 14px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  font-size: 14px;
  margin-left: 0.5rem;
}

.sensor-select {
  display: block;
  margin-bottom: 0.25rem;
}

.sensor-select select {
  height: 28px;
}

.bins-slider {
  margin: 0 1rem;
  margin-bottom: 2rem;
  width: auto;
}

.height-slider {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  width: 600px;
}

.fbswap {
  margin-left: 16px;
}

span.keys {
  margin-right: 16px;
}

div.key-box {
  margin-bottom: 8px;
}

button.analyzeOption {
	width:40px;
  height:40px;
  margin: 10px;
	background-color:#C09;
	color:#FFF;
  border-radius:35px;
  border-width:1px;
	text-align:center;
  box-shadow: 2px 2px 3px #999;
  font-size:16px;
}

div.fs, div.bs {
  display: inline-block;
  width: 30%;
  height: 100px;
  font-size: 11px;
  overflow-y: scroll;
}

div.fs, div.analyzeMatrix .row .coll.f {
  background-color: #bcffcb;
}

div.bs, div.analyzeMatrix .row .coll.b {
  background-color: #afafff;
}

input.analyzeOption {
  width: 40px;
}

div.analyzeMatrix{
  display: inline-block;
  min-width: 100px;
  width: fit-content;
  height: fit-content;
  font-size: 11px;
  background-color: #CCC;
  margin-left: 16px;
}

div.analyzeMatrix .row, div.row.controller{
  margin: 0;
}

div.analyzeMatrix .row .coll{
  background-color: white;
  margin: 1px;
  padding: 2px;
  width: 30px;
  height: 16px;
}

div#accordionSensors {
  margin: 1rem;
}

div.analyzeMatrix .row .coll.sensors{
  width: 100px;
}

.pcMatrix input {
  width: 40px;
}

.pcMatrix td {
  padding: 4px;
  font-size: smaller;
}

.pcMatrix div.col-4, .pcMatrix div.col-6 {
  padding-left: 0;
  padding-right: 0;
}

.pcMatrix input {
  font-size: smaller;
}

.pcMatrix-0-0 {
  background-color: #F7F7F7;
}

.pcMatrix-0-1 {
  background-color: #F0F0F0;
}

.pcMatrix-1-0 {
  background-color: #E7E7E7;
}

.pcMatrix-1-1 {
  background-color: #E0E0E0;
}

.bar:hover {
  fill: rgba(255, 0, 0, 0.95);
}
